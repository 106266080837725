/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useSelector } from "react-redux";

import { WebContainerPlatform } from "@elx-element/common/enums";
import { registerHapticsEventListener } from "@elx-element/common/events/listeners";
import { HapticsOptions } from "@elx-element/common/events/types";

import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";

import { selectPlatform } from "../store/main/selectors";

/**
 * Ovládání událostí s požadavkem na haptickou odezvu.
 */
export const useHaptics = (enabled: boolean) => {
  const platform = useSelector(selectPlatform);

  /**
   * Obsluha spuštění haptické odezvy mobilního zařízení.
   */
  const handleHapticsEvent = async (event: CustomEventInit<HapticsOptions | undefined>) => {
    if (platform !== WebContainerPlatform.web) {
      if (event.detail) {
        if (event.detail.impactOptions) {
          await Haptics.impact({ style: event.detail.impactOptions?.style as unknown as ImpactStyle });
        } else if (event.detail.notificationOptions) {
          await Haptics.notification({
            type: event.detail.notificationOptions?.type as unknown as NotificationType,
          });
        } else {
          await Haptics.vibrate({ duration: event.detail.vibrateOptions?.duration ?? 300 });
        }
      } else {
        await Haptics.vibrate();
      }
    }
  };

  useEffect(() => {
    if (enabled) {
      registerHapticsEventListener(event => handleHapticsEvent(event));
    }
  }, [enabled]);
};
