import { Fragment, useEffect, useState } from "react";

import axios from "axios";
import { useAuth } from "react-oidc-context";
import { batch, useDispatch, useSelector, useStore } from "react-redux";
import { Router, useHistory, useLocation } from "react-router-dom";

import { Box, CircularProgress, Link, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useElementContext } from "@elx-element/common/elementContext";
import { CommonErrors, NetworkStatus, Notification } from "@elx-element/common/enums";
import { getConfigurationBoolValue, getConfigurationStringValue } from "@elx-element/common/envconf";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { registerTitleChangeListener } from "@elx-element/common/events/listeners";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { registerConsoleLogger } from "@elx-element/common/logger";
import { getModulesAlerts } from "@elx-element/common/storage";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiConnection } from "@mdi/js";

import { ConfigurationClient, WebContainerClient } from "../../types.generated";

import { AppState } from "../../store";
import {
  isMobilePlatform,
  selectActiveModule,
  selectCulture,
  selectInfoModalIsOpen,
  selectModuleUpdated,
  selectNetworkStatus,
  selectPlatform,
  selectRegisteredModules,
  selectRegisteredModulesLoaded,
  selectRoutes,
  selectWebContainerUpdated,
} from "../../store/main/selectors";
import { setModuleUpdated, setRegisteredModulesLoaded, setWebContainerUpdated } from "../../store/main/slice";

import { useDevicePlatformInfo } from "../../hooks/useDevicePlatformInfo";
import { useElementInformations } from "../../hooks/useElementInformations";
import { useElementMessages } from "../../hooks/useElementMessages";
import { useElementMessagesChecker } from "../../hooks/useElementMessagesChecker";
import { useGpsTrackingControl } from "../../hooks/useGPSTrackingControl";
import { useHaptics } from "../../hooks/useHaptics";
import { useHardwareBackButton } from "../../hooks/useHardwareBackButton";
import { useModuleSwitchEvents } from "../../hooks/useModuleSwitchEvents";
import { useNetworkConnection } from "../../hooks/useNetworkConnection";
import { useNfcWriter } from "../../hooks/useNfcWriter";
import { useNotificationEvents } from "../../hooks/useNotificationEvents";
import { useNotificationSubscribeEvents } from "../../hooks/useNotificationSubscribeEvents";
import { useResizeListener } from "../../hooks/useResizeListener";
import { useScannerControl } from "../../hooks/useScannerControl";
import { useSoundEvents } from "../../hooks/useSoundEvents";
import useTexts from "../../hooks/useTexts";
import { useWhispererEvents } from "../../hooks/useWhispererEvents";

import useStyles from "./styles";

import {
  checkModulesLicenseValidity,
  createModuleCompatibilityAlerts,
  identifyAnonymousError,
  initialLoadData,
  initStorageAuth,
  loadDashboardSettings,
  loadModules,
  loadModulesInterfaces,
  moduleCompatibilityCheck,
} from "./core";

import { WebContainerErrors } from "../../enums";
import History from "../../history";
import TryLaterPage from "../auth/TryLaterPage";
import ErrorDetail from "../error";
import Header from "../header";
import InfoModal from "../infoPopup";
import Navigation from "../navigation";
import Routes from "../routes";
import CameraScanner from "../scanner/CammeraScanner";
import NfcScanner from "../scanner/NfcScanner";
import NfcWriter from "../scanner/NfcWriter";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

const App = () => {
  const dispatch = useDispatch();
  const store = useStore<AppState>();
  const texts = useTexts();

  const auth = useAuth();
  const { user, isAuthenticated } = auth;

  const theme = useTheme();

  const openApp = useSelector(selectActiveModule); // reference na otevřený modul
  const registeredModules = useSelector(selectRegisteredModules);
  const registeredModulesLoaded = useSelector(selectRegisteredModulesLoaded);
  const culture = useSelector(selectCulture);
  const routes = useSelector(selectRoutes);
  const platform = useSelector(selectPlatform);
  const mobilePlatform = useSelector(isMobilePlatform);

  const viewPortMobile = useMediaQuery(theme.breakpoints.down("md"));
  const networkStatus = useSelector(selectNetworkStatus);
  const webcontainerUpdated = useSelector(selectWebContainerUpdated);
  const moduleUpdated = useSelector(selectModuleUpdated);
  const infoModalIsOpen = useSelector(selectInfoModalIsOpen);
  const [serviceWorkerActiveInDebugMode, setServiceWorkerActiveInDebugMode] = useState(false);
  const { classes } = useStyles();
  const { checkTokenAnyRoleExists } = useElementContext();

  // zpracování externích notifikací a zpráv
  useElementMessages(registeredModulesLoaded);

  // hlídíní příchozích externích zpráv a notifikací - signalR
  useElementMessagesChecker(auth.user?.access_token, new WebContainerClient({}), texts.YOU_HAVE_MESSAGES, dispatch);

  // zpracování zvukových notifikací
  useSoundEvents(registeredModulesLoaded);

  // zpracování požadavků na přepnutí aplikace
  useModuleSwitchEvents(registeredModulesLoaded);

  // zpracování interních notifikací
  useNotificationEvents(registeredModulesLoaded);

  // obsluha odběru externích notifikací
  useNotificationSubscribeEvents(registeredModulesLoaded);

  // obsluha požadavků na našeptávání informací
  useWhispererEvents(registeredModulesLoaded);

  // obsluha pro požadavky na sdílení informací
  useElementInformations(registeredModulesLoaded);

  // ošetření výpadku internetového spojení
  useNetworkConnection();

  // Funkce pro obsluhu skenování
  useScannerControl(registeredModulesLoaded && mobilePlatform);

  // Funkce pro obsluhu NFC zápisu
  useNfcWriter(registeredModulesLoaded && mobilePlatform);

  // Fukce pro obsluhu hw tlačítka zpět (android)
  useHardwareBackButton(registeredModulesLoaded && mobilePlatform);

  // Funkce pro ovládání kontinuálního snímání GPS
  useGpsTrackingControl(registeredModulesLoaded && mobilePlatform);

  // Funce pro nastavení typu použivého zařízení/platformy, v případě že se jedná o mobilní zařízení pak spuštění listenerů událostí
  useDevicePlatformInfo();

  // Funkce pro obsluhu změny velikosti okna
  useResizeListener();

  // Funkce pro obsluhu požadavků na haptickou odezvu
  useHaptics(registeredModulesLoaded && mobilePlatform);

  /**
   * Registrace eventů
   */
  useEffect(
    () => {
      navigator.serviceWorker?.ready?.then(() => {
        if (process.env.NODE_ENV !== "production") {
          setServiceWorkerActiveInDebugMode(true);
        }
      });

      if (registeredModulesLoaded) {
        registerTitleChangeListener();
        checkModulesLicenseValidity(store.getState().main.registeredModules, texts, culture);
      }
    },
    [registeredModulesLoaded] /* Pokud se změní tento parametr, kod se provede znovu */
  );

  /**
   * Zjišťování, zda došlo k aktualizace modulu, či webcontaineru
   */
  useEffect(() => {
    let finished = false;
    let timeout = setTimeout(() => {});
    const process = async () => {
      try {
        const responseWebContainer = await fetch(`/interface.json?refresh=${Date.now()}`, {
          cache: "reload",
          headers: {
            "x-cache": "reload",
          },
        }).then(item => item.json());

        let responseModule: any;

        if (openApp) {
          responseModule = await fetch(`/modules/${openApp.moduleId}/interface.json?refresh=${Date.now()}`, {
            cache: "reload",
            headers: {
              "x-cache": "reload",
            },
          }).then(item => item.json());
        }

        batch(() => {
          const newModuleUpdated = responseModule?.updateRequired ?? false;
          const newWebContainerUpdated = responseWebContainer?.updateRequired ?? false;
          if (newModuleUpdated !== !!moduleUpdated) {
            dispatch(setModuleUpdated(!!newModuleUpdated));
          }
          if (newWebContainerUpdated !== !!webcontainerUpdated) {
            dispatch(setWebContainerUpdated(!!newWebContainerUpdated));
          }
        });
      } catch (e) {
        console.debug(e);
      }
      if (!finished) {
        timeout = setTimeout(process, 60000 * 5);
      }
    };
    process();
    return () => {
      finished = true;
      clearTimeout(timeout);
    };
  }, [openApp?.moduleId, moduleUpdated, webcontainerUpdated]);
  /**
   * Registrace loggeru a listeneru jeho změn, přesměrování na root při startu aplikace, ošetření výpadku internetového spojení
   */
  useEffect(() => {
    registerConsoleLogger(
      process.env.REACT_APP_NAME!,
      /* kolekce ignorovaných chyb, pro které se negeneruje notifikace */
      [
        `webUi.common.${CommonErrors.ignoredAnonymousError}`,
        `webUi.common.${WebContainerErrors.signalRConnectionError}`,
        `WebcontainerApi.Business.Wiki.DocumentNotFound`, // 404 load dat nápovědy
      ],
      identifyAnonymousError,
      debug
    );
    initStorageAuth();
  }, []);

  /**
   * InitialLoad - Načtení registrací modulů - ověření kompatibility modulů - Načtení zpráv, notifikací, uživatelů
   */
  useEffect(
    () => {
      if (isAuthenticated) {
        if (!registeredModulesLoaded) {
          batch(async () => {
            await loadModules(
              new ConfigurationClient(
                {},
                undefined,
                axios.create({
                  headers: {
                    "x-cache-required": "yes",
                  },
                })
              ),
              dispatch
            );
            await loadDashboardSettings(
              new ConfigurationClient(
                {},
                undefined,
                axios.create({
                  headers: {
                    "x-cache-required": "yes",
                  },
                })
              ),
              dispatch
            );

            // postupné stahování interface souborů dostupných modulů
            await loadModulesInterfaces(
              store.getState().main.registeredModules,
              dispatch,
              getConfigurationBoolValue(window.env.webcontainer, "LOAD_SERVER_MODULES")
                ? getConfigurationStringValue(window.env.webcontainer, "SERVER_MODULES_PATH")
                : undefined
            );

            // Spustí test kompatibility modulů a sestaví seznam případných chyb
            await moduleCompatibilityCheck(store.getState().main.registeredModules, dispatch);
            // Záznam nekompatibilit do sessionStorage. Odtud čerpají data další moduly.
            await createModuleCompatibilityAlerts(store.getState().main.registeredModules, texts, culture);
            // nastavení příznaku load complete
            dispatch(setRegisteredModulesLoaded());
          });
        }
      }
    },
    [auth] /* Pokud se změní tento parametr, kod se provede znovu */
  );

  /**
   * Nastavení TITLE aplikace.
   */
  useEffect(() => {
    document.title =
      openApp?.getLocalizedName(culture) ?? getConfigurationStringValue(window.env.webcontainer, "DEFAULT_TITLE");
  }, [openApp]);

  /**
   * V případě potíží s kompatibilitou vygenerujeme notifikaci s upozorněním.
   * Notifikaci nezobrazujeme v případě že chybné moduly jsou skryty.
   */
  useEffect(() => {
    if (registeredModulesLoaded) {
      // Load users, messages, notifications, applicationEvents, subcriptions
      initialLoadData(new WebContainerClient({}), dispatch);

      // Zobrazení upozornění na nekompatibilitu
      const moduleIncompatibilityAlerts = getModulesAlerts();
      if (
        isAuthenticated &&
        moduleIncompatibilityAlerts.length > 0 &&
        registeredModules
          .filter(
            x =>
              !x.requiredPermission ||
              (checkTokenAnyRoleExists(x.requiredPermission!) &&
                (window.innerWidth >= theme.breakpoints.values.md || x.allowOnMobile))
          )
          .some(x => moduleIncompatibilityAlerts.map(y => y.moduleId).includes(x.moduleId))
      ) {
        dispatchNotificationEvent(
          new NotificationEventModel(texts?.INCOMPATIBILITY_NOTIFICATION, Notification.warning),
          debug
        );
      }
    }
  }, [registeredModulesLoaded]);

  return (
    <Box className={classes.root}>
      {platform === undefined && (
        <div className={classes.centeredContent}>
          <CircularProgress />
        </div>
      )}
      {platform !== undefined && (
        <Router history={History}>
          <CapacitorUrlValidator />
          {isAuthenticated && (
            <>
              <Header />
              <Navigation />
            </>
          )}

          <main id="mainContent" className={classes.content}>
            {serviceWorkerActiveInDebugMode ? (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                {texts.SERVICEWORKER_ACTIVE}
              </div>
            ) : (
              false
            )}

            {isAuthenticated && networkStatus === NetworkStatus.offline && (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                OFFLINE - {texts.NETWORK_STATUS_OFFLINE}
              </div>
            )}

            {isAuthenticated && (moduleUpdated || webcontainerUpdated) && (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                {[
                  moduleUpdated ? (
                    <a
                      href="/install.html?reload"
                      key={1}
                      onClick={() => {
                        sessionStorage.setItem("urlBeforeRedirect", window.location.pathname);
                      }}
                    >
                      {texts.MODULE_UPDATE_AVAILABLE}
                    </a>
                  ) : (
                    false
                  ),
                  webcontainerUpdated ? (
                    <a
                      href="/install.html?reload"
                      key={2}
                      onClick={() => {
                        sessionStorage.setItem("urlBeforeRedirect", window.location.pathname);
                      }}
                    >
                      {texts.WEBCONTAINER_UPDATE_AVAILABLE}
                    </a>
                  ) : (
                    false
                  ),
                ]
                  .filter(i => !!i)
                  .reduce(
                    (acc, r, key) => acc.concat([r, <Fragment key={`r${key}`}>&nbsp;</Fragment>]),
                    [] as (JSX.Element | boolean)[]
                  )}
              </div>
            )}

            {auth.isAuthenticated && user?.expired && (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                <a href="/login">{texts.LOGIN_REQUESTED}</a>
              </div>
            )}

            <Routes
              modules={registeredModules}
              modulesLoaded={registeredModulesLoaded}
              routes={routes}
              userAuthorized={isAuthenticated}
              modulePath={
                getConfigurationBoolValue(window.env.webcontainer, "LOAD_SERVER_MODULES")
                  ? getConfigurationStringValue(window.env.webcontainer, "SERVER_MODULES_PATH")
                  : undefined
              }
            />

            {((viewPortMobile && !openApp) || !viewPortMobile) && (
              <Box className={classes.copyrightBox}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {"© "}
                  <Link color="inherit" href={texts.COPYRIGHT_LINK} target="_blank">
                    {texts.COPYRIGHT_TEXT}
                  </Link>
                  {` ${new Date().getFullYear()}`}
                </Typography>
              </Box>
            )}
          </main>
        </Router>
      )}
      {mobilePlatform && (
        <>
          <CameraScanner />
          <NfcScanner />
          <NfcWriter />
        </>
      )}
      {!isAuthenticated && networkStatus === NetworkStatus.offline && (
        <div className={classes.centeredContentOffline}>
          <TryLaterPage />
        </div>
      )}
      {infoModalIsOpen && <InfoModal />}
      <ErrorDetail />
    </Box>
  );
};

/**
 * Podpůrná komponenta pro offline běh.
 * Capacitor ma neustale tendenci prehodit url zpet na install.html, ac uz bylo preroutovano do hlavni casti aplikace
 */
const CapacitorUrlValidator = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.match(/^\/install.html.*/)) {
      history.replace("/dashboard");
    }
  }, [location.pathname]);
  // nemohu vrátit false a nechci vracet div či jiný element
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment />;
};

export default App;
