/* eslint-disable no-param-reassign */
import { Dispatch } from "react";

import { batch } from "react-redux";

import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { CommonErrors, ModuleInterfaceDirectionFlag, Notification } from "@elx-element/common/enums";
import { getConfigurationBoolValue } from "@elx-element/common/envconf";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { LogRecord } from "@elx-element/common/logger/types";
import { storeModulesAlerts } from "@elx-element/common/storage";
import { ModuleAlert } from "@elx-element/common/storage/types";
import { LocalizedName, ModuleInterface, RegisteredModule } from "@elx-element/common/types";
import { format } from "@elx-element/common/utils";
import { checkTokenAnyRoleExists } from "@elx-element/common/utils/token";

import { ConfigurationClient, ModuleModel, WebContainerClient } from "../../types.generated";

import {
  confirmModuleInterfaceEvent,
  setDashboardSettings,
  setModuleConfigExists,
  setModuleInterface,
  setRegisteredModules,
} from "../../store/main/slice";
import { addUsers } from "../../store/message/slice";

import { WebContainerApplication, WebContainerErrors } from "../../enums";
import Lang from "../../languages/lang";

const semver = require("semver");

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

/**
 * Test dostupnosti url
 * @param url keycloak server url
 */
export const checkServerAvailability = async (url: string) =>
  new Promise<number | undefined>(resolve => {
    fetch(url)
      .then(response => {
        resolve(response.status);
      })
      .catch(_ => {
        resolve(undefined);
      });
  });

/**
 * initStorageAuth
 */
export const initStorageAuth = () => {
  // smazání informací o uživateli po unloadu stránky
  // dělalo to problém při refreshi stránky
  if (!window.inCapacitor) {
    window.addEventListener("unload", window.sessionStorage.clear);
  }
};

/**
 * Proces potvrzování komunikace navzájem komunikujících modelů.
 * Na konci procesu musejí být všechny eventy a routy potvrzené => příznak confirmed = true.
 * @param registeredModules
 * @param dispatch
 */
export const moduleCompatibilityCheck = async (registeredModules: RegisteredModule[], dispatch: Dispatch<any>) => {
  // synchronní cyklus potvrzování událostí
  // eslint-disable-next-line no-restricted-syntax
  for (const module of registeredModules) {
    // eslint-disable-next-line no-restricted-syntax
    for (const event of module.interface?.events ?? []) {
      dispatch(confirmModuleInterfaceEvent({ moduleId: module.moduleId, event }));
    }
  }
};

/**
 * Oznámení o nekompatibilitě modulů nebo chybějících součástech budou nalezeny a uloženy do sessionStorage.
 * @param registeredModules
 */
export const createModuleCompatibilityAlerts = async (
  registeredModules: RegisteredModule[],
  texts: Lang,
  culture: string
) => {
  const alerts: Array<ModuleAlert> = [];

  for (let i = 0; i < registeredModules.length; i++) {
    const module = registeredModules[i];

    // test na existenci nepotvrzených událostí ve vztahu volající - volaný modul
    module.interface?.events
      ?.filter(y => !y.confirmed && y.directionFlag === ModuleInterfaceDirectionFlag.out)
      ?.forEach(x => {
        // SWDE-9517 upozornění generujeme pouze pokud se jedná o require dependency
        // a nebo v případě optional dependency za předpokladu že jsou nainstalovány oba moduly
        if (x.required || registeredModules.some(y => y.moduleId === x.moduleId)) {
          alerts.push({
            moduleId: module.moduleId,
            alertMessage: format(texts.MODULE_INCOPATIBLE_EVENT, x.eventName, module.getLocalizedName(culture)),
            required: x.required ?? false,
          });
        }
      });

    // test na neexistenci konfiguračního souboru
    if (!module.configExists) {
      alerts.push({
        moduleId: module.moduleId,
        alertMessage: format(texts.CONFIG_FILE_MISSING, module.getLocalizedName(culture)),
        required: true,
      });
    }

    // cyklus pro test existence a kompatibility dependencies
    if (module.interface?.dependencies) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [dependentModuleId, wantedVersionExp] of Object.entries(module.interface.dependencies)) {
        // wantedVersionExp - výraz definující potřebnou verzi závislého modulu
        if (wantedVersionExp) {
          // dependencyName název závislosti
          let dependencyName: string | undefined;
          // currentVersion - současná verze závislosti
          let currentVersion: string | undefined;
          // závislost modulu na verzi webcontaineru
          if (dependentModuleId === process.env.REACT_APP_NAME) {
            dependencyName = process.env.REACT_APP_NAME;
            currentVersion = process.env.REACT_APP_VERSION;
          } else {
            // závislost modulu na verzi jiného modulu
            const dependentModule = registeredModules.find(x => x.moduleId === dependentModuleId);
            if (dependentModule) {
              // závislý modul je již nainstalován
              dependencyName = dependentModule.getLocalizedName(culture);
              currentVersion = dependentModule?.version;
            } else {
              // neznámá závislost
              dependencyName = dependentModuleId;
              currentVersion = undefined;
            }
          }

          if (
            (currentVersion && !semver.satisfies(currentVersion, wantedVersionExp)) ||
            (process.env.NODE_ENV === "production" && currentVersion === undefined)
          ) {
            alerts.push({
              moduleId: module.moduleId,
              alertMessage: format(
                texts.MODULE_MISSING_DEPENDENCY,
                `${module.getLocalizedName(culture)}@${module.version}`,
                `${dependencyName}@${wantedVersionExp}`
              ),
              required: true,
            });
          }
        }
      }
    }
  }

  // uložení do sessionStorage
  storeModulesAlerts(alerts);
};

/**
 * Kontrola platnosti licencí pro použití modulů
 * @param registeredModules
 */
export const checkModulesLicenseValidity = async (
  registeredModules: RegisteredModule[],
  texts: Lang,
  culture: string
) => {
  let warningLicenseMessage = "";
  let errorLicenseMessage = "";

  registeredModules
    .filter(x => x.requiredPermission && checkTokenAnyRoleExists(x.requiredPermission))
    .forEach(module => {
      if (module.licenseExpiring()) {
        warningLicenseMessage += `${module.getLocalizedName(culture) ?? module.moduleId}, `;
      } else if (module.licenseExpired()) {
        errorLicenseMessage += `${module.getLocalizedName(culture) ?? module.moduleId}, `;
      }
    });

  if (warningLicenseMessage.length > 0) {
    dispatchNotificationEvent(
      new NotificationEventModel(
        format(texts.LICENSE_NOTIFICATION, warningLicenseMessage.replace(/. $/, ".")),
        Notification.warning,
        undefined,
        undefined,
        undefined,
        true,
        WebContainerApplication.licenses
      ),
      debug
    );
  }

  if (errorLicenseMessage.length > 0) {
    dispatchNotificationEvent(
      new NotificationEventModel(
        format(texts.LICENSE_ERROR_NOTIFICATION, errorLicenseMessage.replace(/. $/, ".")),
        Notification.error,
        undefined,
        undefined,
        undefined,
        true
      ),
      debug
    );
  }
};

/**
 * Load interface souboru modulu definovaného parametrem moduleId
 * @param moduleId
 * @param modulePath
 */
export const loadModuleInterface = (moduleId: string, modulePath?: string) =>
  new Promise<{
    moduleId: string;
    interface: ModuleInterface;
    version: string | undefined;
    buildNumber: string | undefined;
  } | void>(resolve => {
    fetch(`${modulePath ?? ""}/modules/${moduleId}/interface.json?v=${Date.now()}`)
      .then(response => response.json())
      .then(response => {
        resolve({
          moduleId,
          interface: {
            ...((response?.interface ?? {}) as ModuleInterface),
            availableOfflineMode: !!response.availableOfflineMode,
          },
          version: response.version,
          buildNumber: response.buildNumber,
        });
      })
      .catch(() => {
        throwSpecificError(
          CommonErrors.missingDependency,
          `Module interface is missing in location ${modulePath ?? ""}/modules/${moduleId}/interface.json.`
        );
        resolve();
      });
  });

export const loadModuleWhisperer = (moduleId: string, modulePath?: string) =>
  new Promise<{
    moduleId: string;
  } | void>(resolve => {
    fetch(`${modulePath ?? ""}/modules/${moduleId}/whisperer.configuration.json?v=${Date.now()}`)
      .then(response => response.json())
      .then(response => {
        window.env.whisperer = { ...window.env.whisperer, [moduleId]: response };
        const script = document.createElement("script");
        script.src = `${modulePath ?? ""}/modules/${moduleId}/whisperer.js?v=${Date.now()}`;
        script.async = true;
        script.type = "module";
        script.setAttribute("data-script-type", "module-whisperer-script");
        if (!document.body.contains(script)) {
          document.body.appendChild(script);
        }
      })
      .catch(() => {
        throwSpecificError(
          CommonErrors.missingDependency,
          `Whisperer configuration is missing in location ${
            modulePath ?? ""
          }/modules/${moduleId}/whisperer.configuration.json`
        );
      });

    resolve();
  });

/**
 * Load konfiguračního souboru modulu definovaného parametrem moduleId
 * @param moduleId
 * @param modulePath
 */
export const loadModuleConfiguration = (moduleId: string, dispatch: Dispatch<any>, modulePath?: string) =>
  new Promise<void>(resolve => {
    fetch(`${modulePath ?? ""}/modules/${moduleId}/configuration.js?v=${Date.now()}`)
      .then(response => {
        if (response.headers.get("content-type")?.includes("application/javascript")) {
          dispatch(setModuleConfigExists(moduleId));
        } else {
          throwSpecificError(
            CommonErrors.missingDependency,
            `Module configuration is missing in location ${modulePath ?? ""}/modules/${moduleId}/configuration.js`
          );
        }
        resolve();
      })
      .catch(_ => {
        throwSpecificError(
          CommonErrors.missingDependency,
          `Module configuration is missing in location ${modulePath ?? ""}/modules/${moduleId}/configuration.js`
        );
        resolve();
      });
  });

/**
 * Provede načtení rozhraní všech zaregistrovaných modulů. Pro každý modul je následně  provedena registrace metod z rozhraní.
 * @param registeredModules Seznam zaregistrovaných modulů.
 * @param registeredModules
 * @param dispatch
 * @param modulePath
 */
export const loadModulesInterfaces = async (
  registeredModules: RegisteredModule[],
  dispatch: Dispatch<any>,
  modulePath?: string
) => {
  const interfacesJsonData = await Promise.all(
    registeredModules
      .filter(x => x.externalModule)
      .map(async module => {
        if (module.externalModule) {
          await loadModuleConfiguration(module.moduleId, dispatch, modulePath);
        }
        return loadModuleInterface(module.moduleId, modulePath);
      })
  );

  await Promise.all(
    // eslint-disable-next-line array-callback-return, consistent-return
    interfacesJsonData.map(async moduleInterface => {
      if (moduleInterface) {
        // pokud interface povoluje použití našeptávače, připojíme skript
        if (moduleInterface.interface.services?.whisperer) {
          await loadModuleWhisperer(moduleInterface.moduleId, modulePath);
        }
        return dispatch(
          setModuleInterface({
            moduleId: moduleInterface.moduleId,
            moduleInterface: moduleInterface.interface,
            version: moduleInterface.version,
            buildNumber: moduleInterface.buildNumber,
          })
        );
      }
    })
  );
};

/**
 * Load definic modulů z API
 * @param client
 * @param dispatch
 */
export const loadModules = async (client: ConfigurationClient, dispatch: Dispatch<any>) => {
  const data: Array<RegisteredModule> = [];
  const modules = (await apiMan(client.getModules())) as ModuleModel[];
  if (modules) {
    modules.forEach(x => {
      data.push(
        new RegisteredModule(
          x.moduleId!,
          x.route!,
          x.localizedNames as Array<LocalizedName>,
          x.externalModule,
          x.showOnMobile,
          x.permission,
          x.tileIcon,
          x.tileColor,
          x.widgets,
          x.license?.expiration?.toString(),
          x.license?.type,
          x.order
        )
      );
    });
    /** Registrace modulů do core storu */
    dispatch(setRegisteredModules(data));
  }
};

/**
 * Uložení uživatelů aplikace, kterým lze adresovat zprávu.
 * @param client
 * @param dispatch
 */
export const loadUsers = async (client: WebContainerClient, dispatch: Dispatch<any>) => {
  const users = await apiMan(client.user(1000, 0));
  if (users) {
    dispatch(addUsers(users));
  }
};

/**
 * Load nastavení dashboardu konkrétního uživatele
 * @param client
 * @param dispatch
 */
export const loadDashboardSettings = async (client: ConfigurationClient, dispatch: Dispatch<any>) => {
  const dashboardSettings = await apiMan(client.getDashboardSettings());
  if (dashboardSettings) {
    dispatch(setDashboardSettings(dashboardSettings));
  }
};

/**
 * Initial data load
 * @param webContainerClient
 * @param applicationEventClient
 * @param dispatch
 */
export const initialLoadData = async (webContainerClient: WebContainerClient, dispatch: Dispatch<any>) => {
  batch(async () => {
    loadUsers(webContainerClient, dispatch);
  });
};

/**
 * Pokus o identifikaci některých anonymních chyb. Pokud se chybu podaří identifikovat, je přiřazen konkretkní chybový kód.
 * @param message Znění chyby
 */
export const identifyAnonymousError = (error: LogRecord): LogRecord => {
  // Potlačení zobrazování chyby při vypnutém snímání GPS na mobilním zařízení.
  if (
    typeof error.message === "string" &&
    error.message.replace(/\s+/g, "").replace(/'+/g, "").replace(/"+/g, "").toLowerCase() ===
      "{message:locationunavailable}"
  ) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  }
  // Při nuceném odříznutí služby (restart kontejneru v azure) je ukončeno spojení SignalR a vygenerována následující chyba.
  // Webcontainer automaticky restartuje spojení. Proto není nutné tuto chybu zobrazovat uživateli.
  else if (JSON.stringify(error.message).includes("WebSocket closed with status code: 1006 ().")) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  }
  // V některých případech SignalR byla komunikace neočekávaně ukončena následující chybou.
  // Webcontainer spojení automaticky obnoví, viz debug console.
  else if (
    JSON.stringify(error.message).includes("Error: Server timeout elapsed without receiving a message from the server.")
  ) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  } else if (JSON.stringify(error.message).includes("Error: Failed to complete negotiation with the server")) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  }
  // Vůbec se nepodařilo navázat SignalR spojení, nebude obnoveno.
  else if (JSON.stringify(error.message).includes("Error: Failed to start the connection")) {
    error.errorCode = `webUi.common.${WebContainerErrors.signalRConnectionError}`;
  }

  return error;
};

/**
 * Všechny komponenty na stránce musejí být zneviditelněny, protože komponenta fotoaparátu se renderuje pod stránku s aplikací.
 */
export const hideWebcontainerBackground = () => {
  document.getElementById(`${process.env.REACT_APP_NAME}_root`)!.classList.add("hidden");
  document.querySelectorAll("[role='presentation']").forEach(e => {
    if (e.getAttribute("data-app") !== process.env.REACT_APP_NAME) {
      e.classList.add("hidden");
    }
  });
  document.body.classList.add("transparent");
};

/**
 * Po zavření scanneru opět zviditelníme všechny části aplikace.
 */
export const showWebcontainerBackground = () => {
  document.getElementById(`${process.env.REACT_APP_NAME}_root`)!.classList.remove("hidden");
  document.querySelectorAll("[role='presentation']").forEach(e => {
    e.classList.remove("hidden");
  });
  document.body.classList.remove("transparent");
};
