/* eslint-disable import/prefer-default-export */
import { SnackbarProvider } from "notistack";
import { Log } from "oidc-client-ts";
import ReactDOM from "react-dom";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "react-redux";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { ElementProvider } from "@elx-element/common/elementContext";
import { getConfigurationBoolValue } from "@elx-element/common/envconf";

// eslint-disable-next-line import/order
import createCache from "@emotion/cache";
// eslint-disable-next-line import/order
import { CacheProvider } from "@emotion/react";

import store from "./store";

import userManager from "./auth/userManager";
import App from "./components/app";
import Theme from "./theme";

declare global {
  interface Window {
    env: any;
  }
}

const muiCache = createCache({
  key: "mui",
  prepend: true,
});

/** Rendering aplikace */
if (window.top!.env.webcontainer) {
  sessionStorage.clear();
  /**
   * Customizace favicony
   */
  const link = document.querySelector("link[rel*='icon']");
  if (link) {
    link.setAttribute("href", window.top!.env.webcontainer.FAVICON_URL);
  }

  const Root = () => (
    <Provider store={store}>
      <SnackbarProvider maxSnack={10}>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={Theme}>
            <CssBaseline />
            <AuthProvider userManager={userManager}>
              <ElementProvider>
                <App />
              </ElementProvider>
            </AuthProvider>
          </ThemeProvider>
        </CacheProvider>
      </SnackbarProvider>
    </Provider>
  );

  /**
   * Na základě konfigurace lze povolit logování oidc knihovny pro práci s autentizací.
   */
  if (getConfigurationBoolValue(window.top!.env.webcontainer, "ENABLE_DEBUG_LOG")) {
    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);
  }
  ReactDOM.render(<Root />, document.getElementById(`${process.env.REACT_APP_NAME}_root`));
}
